import { Fragment, StrictMode } from 'react'
import { createBrowserRouter, LoaderFunction, Outlet, RouteObject } from 'react-router-dom'
import { Provider } from './Provider'
import { ROUTE_PATHS } from './ROUTE_PATHS.ts'
import './assets/scss/index.scss'
import { currentUserLoader, ProtectedRoutes } from './components/utils/ProtectedRoutes.tsx'
import { ErrorPage } from './pages/ErrorPage.tsx'
import { Documents, documentsLoader } from './pages/documents/Documents.tsx'
import { DefaultLayout, layoutLoader } from './pages/sharedLayouts/DefaultLayout.tsx'

// To avoid circular references, we don't import the store directly in the file
// But we inject the store in that file from the entrypoint of the React App
// which is main.tsx
// See: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
import { legalWatchLoader } from '@/components/legalWatch/use-legalWatchArticleList.hook.tsx'
import { WretchLegalWatchArticleGateway } from '@/gateways/wretchLegalWatchArticle.gateway.ts'
import { LegalWatch } from '@/pages/legal-watch/LegalWatch.tsx'
import { LegalWatchFavorites } from '@/pages/legal-watch/LegalWatchFavorites.tsx'
import { LegalWatchDetails } from '@/pages/legal-watch/[legalWatchId]/LegalWatchDetails.tsx'
import { getArticle } from '@/store/articles/useCases/getArticle.ts'
import { getArticleBySlug } from '@/store/articles/useCases/getArticleBySlug.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { listSourceCollections } from '@/store/sourceCollections/use Cases/listSourceCollections.ts'
import { createRoot } from 'react-dom/client'
import { EmailError } from './components/EmailError.tsx'
import { Page } from './components/atomics/BasePage.tsx'
import { WretchAccountGateway } from './gateways/WretchAccount.gateway.ts'
import { WretchAuthGateway } from './gateways/WretchAuth.gateway.ts'
import { WretchConversationGateway } from './gateways/WretchConversation.gateway.ts'
import { WretchConversationSnapshotGateway } from './gateways/WretchConversationSnapshot.gateway.ts'
import { WretchFileGateway } from './gateways/WretchFile.gateway.ts'
import { WretchSourceCollectionGateway } from './gateways/WretchSourceCollection.gateway.ts'
import { WretchTagGateway } from './gateways/WretchTag.gateway.ts'
import { injectStore, olympeGptApiWretch } from './gateways/olympeGptApiWretch.ts'
import { WretchLegalResourceGateway } from './gateways/wretchLegalResource.gateway.ts'
import { OutagePage } from './pages/OutagePage.tsx'
import { AccountVerificationInfo } from './pages/account-verification-info/AccountVerificationInfo.tsx'
import { ConversationPage } from './pages/conversations/[conversationId]/ConversationPage.tsx'
import { EmailVerification } from './pages/email-verification/EmailVerification.tsx'
import { GreetingUser } from './pages/greeting-user/GreetingUser.tsx'
import LibraryDetailPage from './pages/library/LibraryDetailPage.tsx'
import LibraryPage from './pages/library/LibraryPage.tsx'
import { Login } from './pages/login/Login.tsx'
import { PromptTutorialPage } from './pages/prompt-tutorial/PromptTutorialPage.tsx'
import { RegisterPage } from './pages/register/RegisterPage.tsx'
import { ResetPasswordConfirmation } from './pages/reset-password-confirmation/ResetPasswordConfirmation.tsx'
import { ResetPasswordInfo } from './pages/reset-password-info/ResetPasswordInfo.tsx'
import { ResetPasswordVerification } from './pages/reset-password-verification/ResetPasswordVerification.tsx'
import { ResetPassword } from './pages/reset-password/ResetPassword.tsx'
import SharedConversationsPage from './pages/shared-conversations/SharedConversationsPage.tsx'
import { PublicLayout } from './pages/sharedLayouts/PublicLayout.tsx'
import { listUserConversationsForArticle } from './store/articles/useCases/listUserConversationsForArticle.ts'
import { AppStore, createAppStore, Dependencies } from './store/configureStore.ts'
import { selectAllTexts } from './store/texts/textsSlice.ts'
import { listTexts } from './store/texts/useCases/listTexts.ts'

const authGateway = new WretchAuthGateway(olympeGptApiWretch)
export const conversationGateway = new WretchConversationGateway(olympeGptApiWretch)
const conversationSnaphotGateway = new WretchConversationSnapshotGateway(olympeGptApiWretch)
const sourceCollectionGateway = new WretchSourceCollectionGateway(olympeGptApiWretch)
export const fileGateway = new WretchFileGateway(olympeGptApiWretch)
const tagGateway = new WretchTagGateway(olympeGptApiWretch)
const accountGateway = new WretchAccountGateway(olympeGptApiWretch)

const legalWatchArticleGateway = new WretchLegalWatchArticleGateway(olympeGptApiWretch)
const legalResourceGateway = new WretchLegalResourceGateway(olympeGptApiWretch)

const dependencies: Dependencies = {
  fileGateway,
  conversationGateway,
  conversationSnaphotGateway,
  sourceCollectionGateway,
  authGateway,
  tagGateway,
  accountGateway,
  legalWatchArticleGateway,
  legalResourceGateway,
}
export const store = createAppStore(dependencies)
injectStore(store)

const conversationSingleLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    await store.dispatch(listSourceCollections())
    return null
  }

const legalWatchArticleBySlugLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async ({ params }) => {
    if (params.slug) {
      await store.dispatch(listSourceCollections())
      const article = await store.dispatch(getArticleBySlug(params.slug)).unwrap()
      const conversationList = await store.dispatch(listUserConversationsForArticle(article.id)).unwrap()
      if (conversationList.length > 0) store.dispatch(listConversationMessages(conversationList[0].id))
    }
    return null
  }

const legalWatchArticleLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async ({ params }) => {
    if (params.articleId) {
      await store.dispatch(listSourceCollections())
      await store.dispatch(getArticle(params.articleId))
    }
    return null
  }

export const legalResourceLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    const texts = selectAllTexts(store.getState())
    if (texts.length < 1) {
      await store.dispatch(listTexts())
    }
    return null
  }

const forNotAuthenticatedOnlyRoutes = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: '/',
        element: <PublicLayout withLegalMention={false} />,
        children: [
          {
            path: ROUTE_PATHS.sharedConversations,
            element: (
              <Page title="Conversations partagées">
                <SharedConversationsPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.accountVerificationInfo,
            element: (
              <Page title="Informations de validation d'email">
                <AccountVerificationInfo />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.emailVerification,
            element: (
              <Page title="Vérification d'email">
                <EmailVerification />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPassword,
            element: (
              <Page title="Nouveau mot de passe">
                <ResetPassword />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordTokenExpiredError,
            element: (
              <Page title="Erreur">
                <EmailError tokenIssue="expired" />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordTokenInvalidError,
            element: (
              <Page title="Erreur">
                <EmailError tokenIssue="invalid" />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordVerification,
            element: (
              <Page title="Nouveau mot de passe">
                <ResetPasswordVerification />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordConfirmation,
            element: (
              <Page title="Confirmation">
                <ResetPasswordConfirmation />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordInfo,
            element: (
              <Page title="Confirmation">
                <ResetPasswordInfo />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.greetingUser,
            element: (
              <Page title="Bonjour">
                <GreetingUser />
              </Page>
            ),
          },
        ],
      },
      {
        path: ROUTE_PATHS.register,
        element: (
          <Page title="S'inscrire">
            <RegisterPage />
          </Page>
        ),
      },
      {
        path: ROUTE_PATHS.login,
        element: (
          <Page title="Se connecter">
            <Login />
          </Page>
        ),
      },
    ],
  },
]

// For now, we don't have public pages
// Could be used for pages such as CGUs
const publicRoutes: RouteObject[] = []

const needAuthRoutes: RouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoutes />,
    loader: currentUserLoader({ store }),
    children: [
      {
        path: '/',
        element: <DefaultLayout />,
        loader: layoutLoader({ store }),
        children: [
          {
            path: ROUTE_PATHS.conversationSingle,
            loader: conversationSingleLoader({ store }),
            element: (
              <Page title="Conversation en cours">
                <ConversationPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.documents,
            loader: documentsLoader({ store }),
            element: (
              <Page title="Base de connaissance">
                <Documents />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.library,
            loader: legalResourceLoader({ store }),
            element: (
              <Page title="Bibliothèque Juridique">
                <LibraryPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.libraryDetail,
            element: (
              <Page title="Text Juridique">
                <LibraryDetailPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.promptTutorial,
            element: (
              <Page title="Tutoriel de prompting">
                <PromptTutorialPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.legalWatch,
            loader: legalWatchLoader({ store }),
            element: (
              <Page title="Veille juridique">
                <LegalWatch />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.legalWatchArticle,
            loader: legalWatchArticleLoader({ store }),
            element: (
              <Page title="Article de veille juridique">
                <LegalWatchDetails />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.legalWatchArticleBySlug,
            loader: legalWatchArticleBySlugLoader({ store }),
            element: (
              <Page title="Article de veille juridique">
                <LegalWatchDetails />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.legalWatchFavorites,
            loader: legalWatchLoader({ store }),
            element: (
              <Page title="Article de veille juridique favoris">
                <LegalWatchFavorites />
              </Page>
            ),
          },
        ],
      },
    ],
  },
]
const createRouter = ({ store }: { store: AppStore }) => {
  if (import.meta.env.VITE_FEATURES_ENABLE_OUTAGE_PAGE === 'true') {
    return createBrowserRouter([{ path: '*', element: <OutagePage /> }])
  } else {
    return createBrowserRouter([
      ...publicRoutes,
      ...(store.getState().entities.auth.user ? [] : forNotAuthenticatedOnlyRoutes),
      ...needAuthRoutes,
      { path: ROUTE_PATHS.unexpectedError, element: <ErrorPage /> },
      { path: '*', element: <ErrorPage message="Cette page n'existe pas" /> },
    ])
  }
}

export type AppRouter = ReturnType<typeof createRouter>

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  if (!container) throw new Error("Could not find root element with id 'root'")

  const root = createRoot(container)
  const router = createRouter({ store })

  const disableStrictMode = import.meta.env.VITE_DISABLE_STRICT_MODE === 'true'
  const Container = disableStrictMode ? Fragment : StrictMode

  root.render(
    <Container>
      <Provider store={store} router={router} />
    </Container>,
  )
})
