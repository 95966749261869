import { TheIconOlympeLogoWithText } from './icons/TheIconOlympeLogoWithText.tsx'
import { useNavigate } from 'react-router-dom'
import { KeyboardEvent } from 'react'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { useCountDown } from '../utils/useCountDown.ts'
import { useRedirect } from '../utils/useRedirect.ts'
import { EmailConfirmationPageHeader } from './EmailConfirmationPageHeader.tsx'
import { RedirectionLink } from './RedirectionLink.tsx'

export function EmailValidationConfirmation() {
  const navigate = useNavigate()
  const counter = useCountDown()
  useRedirect(ROUTE_PATHS.login, 3000)

  const handleClick = () => {
    navigate(ROUTE_PATHS.login)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key !== 'Enter') return
    navigate(ROUTE_PATHS.login)
  }

  return (
    <div className={'m-auto flex w-2/3 flex-col items-center gap-12 text-center md:w-11/12'}>
      <div className={'flex flex-col items-center gap-6 text-center'}>
        <TheIconOlympeLogoWithText />
        <EmailConfirmationPageHeader counter={counter} title={'Adresse email vérifiée !'} />
      </div>
      <RedirectionLink handleClick={handleClick} handleKeyPress={handleKeyPress} />
    </div>
  )
}
